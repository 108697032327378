import { ReactElement, useState, useEffect } from "react";
import axios from "axios";
import { useSearchParams } from "react-router-dom";

import {
	AnswersPostProps,
	AnswerPostType,
} from "../type/Post.types";
import { AnswerItem } from "./Item";

export function Answers({
	parentPostId,
	answer
}: AnswersPostProps): ReactElement | null {
	const [answers, setAnswers] = useState<AnswerPostType[]>([]);
	const [showSingleAnswer, setShowSingleAnswer] =
		useState<boolean>(false);
	let path = `/posts/${parentPostId}/responses`;

	const [searchParams, _] = useSearchParams();

	useEffect(() => {
		if (
			searchParams.get("response-id") &&
			searchParams.get("forum-id") == parentPostId
		) {
			const responseId = searchParams.get("response-id");
			path = `/posts/${parentPostId}/responses/${responseId}`;
			setShowSingleAnswer(true);
		}
		getData(path);
	}, [parentPostId]);

	const getData = (path: string) => {
		axios
			.get(path)
			.then((res) => {
				var data = res.data;
				if (Array.isArray(data)) {
					setAnswers(data);
				} else {
					setAnswers(answers.concat(data));
				}
			})
			.catch(() => {
				setShowSingleAnswer(false);
			});
	};

	useEffect(() => {
		const newAnswer: AnswerPostType = answer!;
		if (typeof newAnswer === "undefined") {
			return;
		}
		setAnswers((currentAnswers) => [
			...currentAnswers,
			newAnswer,
		]);
	}, [answer]);

	const handleDelete = (deletedItemId: string) => {
		setAnswers(
			answers.filter((answer) => answer.id !== deletedItemId)
		);
	};

	const handleUpdate =
		(index: number) => (item: AnswerPostType) => {
			let newList = [...answers];
			newList[index] = item;
			setAnswers(newList);
		};

	const handleClick = () => {
		getData(path);
		setShowSingleAnswer(false);
	};

	return (
		<>
			{showSingleAnswer && (
			<div className={'answers-options'}>
				<div onClick={handleClick} className={'basic-button'}>Show all answers</div>
			</div>
			)}
			{answers.map(
				(answerPost: AnswerPostType, index: number) => {
					return (
						<AnswerItem
							answer={answerPost}
							onDelete={handleDelete}
							onUpdate={handleUpdate(index)}
							key={answerPost.id}
						/>
					);
				}
			)}
		</>
	);
}
