import { ReactElement, useState, useEffect } from "react";
import {
	useForm,
	SubmitHandler,
	Controller,
} from "react-hook-form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import {
	MaterialType,
	UploadMaterialFormValues,
} from "../type/Course.types";

export function UploadMaterial({
	courseId,
	addMaterial,
	closeModal,
}: any): ReactElement | null {
	const {
		control,
		register,
		handleSubmit,
		reset,
		setError,
		formState: { errors },
	} = useForm<UploadMaterialFormValues>();

	const [submitting, setSubmitting] = useState<boolean>(false);
	const [pageCount, setPageCount] = useState<number>(0);
	const [pagesProcessedCount, setPagesProcessedCount] =
		useState<number>(0);

	useEffect(() => {
		var wsURL = "ws://localhost:3000/cable";
		if (process.env.NODE_ENV === "production") {
			wsURL = "wss://api.martlet.io/cable";
		} else if (process.env.REACT_APP_ENV === "remote_dev") {
			wsURL = `ws://${process.env.REACT_APP_WS}/cable`;
		}
		const ws = new WebSocket(wsURL);

		ws.onopen = () => {
			ws.send(
				JSON.stringify({
					command: "subscribe",
					identifier: JSON.stringify({
						channel: "PdfSplitProgressChannel",
					}),
				})
			);
		};

		ws.onmessage = (e) => {
			const data = JSON.parse(e.data);
			if (data.type === "ping") return;
			if (data.type === "welcome") return;
			if (data.type === "confirm_subscription") return;
			const currentPagesProcessedCount = data.message.pageCount;
			setPagesProcessedCount(currentPagesProcessedCount);
			const material = data.message.material;
			if (typeof material != "undefined") {
				handlePageProcessing(material);
				return () => {
					ws.close();
				};
			}
		};

		return () => {
			ws.close();
		};
	}, []);

	const onSubmit: SubmitHandler<UploadMaterialFormValues> = (
		data
	) => {
		setSubmitting(true);
		const formData = new FormData();
		const materialUpload = data.material[0];
		if (materialUpload.type !== "application/pdf") {
			setError("material", {
				type: "filetype",
				message: "Only PDFs are valid.",
			});
			setSubmitting(false);
			return;
		}
		formData.append("document", data.material[0]);
		formData.append("document_title", materialUpload.name); //returns as '' if empty
		if (data.classDate) {
			formData.append(
				"class_date",
				data.classDate.toISOString()
			);
		} else {
			let defaultDate = new Date();
			formData.append("class_date", defaultDate.toISOString());
		}
		if (data.title) {
			formData.append("title", data.title); //returns as '' if empty
		} else {
			formData.append("title", materialUpload.name);
		}
		uploadMaterial(formData);
	};

	const uploadMaterial = (data: any) => {
		axios
			.post(`/courses/${courseId}/materials`, data)
			.then((res) => {
				setPageCount(res.data.pageCount);
			})
			.catch(() => {
				setSubmitting(false);
			});
	};

	const handlePageProcessing = (material: MaterialType) => {
		// TODO: close the connection when all pages are loaded
		addMaterial(material);
		reset();
		setSubmitting(false);
		closeModal();
	};

	return (
		<div className="courses-container">
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="form-field">
					<div>
						<input
							id="title"
							placeholder="Enter documents title"
							{...register("title")}
						/>
					</div>

					<div>
						<Controller
							name="classDate"
							control={control}
							render={({ field }) => (
								<DatePicker
									placeholderText="Select date"
									onChange={(date) => field.onChange(date)}
									dateFormat="yyyy-MM-dd"
									selected={field.value}
								/>
							)}
						/>
					</div>

					<div>
						<input
							id="material"
							{...register("material", {
								required: "You must provide a PDF file",
							})}
							type="file"
						/>
					</div>
				</div>

				{errors.title && (
					<li className="form-error">{errors.title.message}</li>
				)}
				{errors.classDate && (
					<li className="form-error">
						{errors.classDate.message}
					</li>
				)}
				{errors.material && (
					<li className="form-error">
						{errors.material.message}
					</li>
				)}

				<button disabled={submitting}>
					{submitting && (
						<i
							className="fa fa-refresh fa-spin"
							style={{ marginRight: "5px" }}
						/>
					)}
					{submitting && (
						<span>Adding Document. Please wait</span>
					)}
					{!submitting && <span>Add Document</span>}
				</button>
			</form>

			{submitting && (
				<div>
					<div>
						Processing page {pagesProcessedCount} of {pageCount}{" "}
						total pages.
					</div>
					<div>
						When the document is finished uploading you will be
						sent an email indicating the document has succesfully
						been uploaded.
					</div>
				</div>
			)}
		</div>
	);
}
