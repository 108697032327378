import { ReactElement, useState, useEffect } from "react";
import { useSearchParams, Link } from "react-router-dom";
import axios from "axios";

export function RegisterConfirmation(): ReactElement | null {
	const [registrationConfirmed, setRegistrationConfirmed] =
		useState<boolean>(false);

	const [searchParams, _] = useSearchParams();
	const confirmationToken = searchParams.get("confirmation_token");

	useEffect(() => {
		axios
			.get(`/users/confirmation?confirmation_token=${confirmationToken}`)
			.then(() => {
				setRegistrationConfirmed(true);
			})
			.catch(() => {
				setRegistrationConfirmed(false);
			});
	}, [searchParams]);

	return (
		<>
			{registrationConfirmed && (
				<div>
					<h3>Your email has successfully been confirmed!</h3>
					<div>
						<Link to={`/login`}>Click here to sign in!</Link>
					</div>
				</div>
			)}
		</>
	);
}
