import { ReactElement, useState } from "react";

import { CommentPostItemProps } from "../type/Post.types";

import { Post } from "../Post";
import { UpdatePostForm } from "../Forms/Update";
import "../styles/comment.css";

export function CommentItem({
	comment,
	onDelete,
	onUpdate,
}: CommentPostItemProps): ReactElement | null {
	const [showUpdateForm, setShowUpdateForm] =
		useState<boolean>(false);

	return (
		<div className="comment-layout">
			{!showUpdateForm && (
				<Post
					post={comment}
					onDelete={onDelete}
					toggleForm={() => {
						setShowUpdateForm(!showUpdateForm);
					}}
				/>
			)}

			{showUpdateForm && (
				<UpdatePostForm
					post={comment}
					toggleForm={() => {
						setShowUpdateForm(!showUpdateForm);
					}}
					onUpdate={onUpdate}
				/>
			)}
		</div>
	);
}
