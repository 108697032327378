import { ReactElement, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../../components/Auth/contexts/UserContext";

type FormValues = {
	email: string;
	password: string;
};

export function Login(): ReactElement | null {
	const [serverErrors, setServerErrors] = useState<string>();
	const [submitting, setSubmitting] = useState<boolean>(false);
	const { login } = useAuth();

	const navigate = useNavigate();
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<FormValues>();

	const onSubmit: SubmitHandler<FormValues> = (data) => {
		const config = {
			headers: {
				"Content-Type": "application/json",
				Accept: "application/html",
			},
		};
		axios
			.post("/users/sign_in", data, config)
			.then((res) => {
				login(res.data);
				navigate("/courses/my-courses", { replace: true });
			})
			.catch((err) => {
				setServerErrors(err.response.data);
			});

		setSubmitting(false);
	};

	return (
		<div>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div>
					<label>Email </label>
					<input
						{...register("email", {
							required: "You must specify an email",
							pattern: {
								value: /^\S*$/,
								message:
									"Invalid name. Please do not use spaces",
							},
						})}
						className={"ml-2"}
					/>
					{errors.email && <p>{errors.email.message}</p>}
				</div>

				<div className={"mt-2"}>
					<label>Password </label>
					<input
						type="password"
						{...register("password", {
							required: "You must specify a password",
						})}
					/>
					{errors.password && <p>{errors.password.message}</p>}
				</div>

				{serverErrors && (
					<div>
						<p className="form-error">{serverErrors}</p>
					</div>
				)}

				<div>
					<button type="submit" disabled={submitting}>
						Login
					</button>
				</div>
			</form>
			<div>
				<Link to={`/confirm-email`}>Forgot your password?</Link>
			</div>
		</div>
	);
}
