import { ReactElement, useState, useContext } from "react";
import { FindCourseForm } from "../../components/Course/InstitutionCourses/FindCourse";
import { Course } from "../../components/Course/InstitutionCourses/Item";
import { CourseType } from "../../components/Course/type/Course.types";
import { UserContext } from "../../components/Auth/contexts/UserContext";
import { CreateFolder } from "../../components/Course/MyCourses/CreateFolder";
import Modal from "react-modal";

Modal.setAppElement("#root");
export function InstitutionCourses(): ReactElement | null {
	const [courses, setCourses] = useState<CourseType[]>([]);
	const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
	const [noCoursesFound, setNoCoursesFound] =
		useState<boolean>(false);
	const { user } = useContext(UserContext);
	const institutionNameSlug = user?.institutionName
		.toLowerCase()
		.replace(/ /g, "-")
		.replace(/[^\w-]+/g, "");

	const handleSearchCourses = (newCourses: CourseType[]) => {
		setCourses(newCourses);
		setNoCoursesFound(false);
		if (newCourses.length == 0) {
			setNoCoursesFound(true);
		}
	};

	const handleAddCourses = (newCourse: CourseType) => {
		setCourses([...courses, newCourse]);
	};

	const modal = (
		<Modal
			isOpen={modalIsOpen}
			onRequestClose={() => {
				setModalIsOpen(false);
			}}
		>
			<div className="modal-container">
				<div className="action-title">Create Folder</div>

				<div className="close-modal">
					<button onClick={() => setModalIsOpen(false)}>
						Close
					</button>
				</div>

				<div className="upload-material">
					<CreateFolder
						closeModal={() => setModalIsOpen(false)}
						institution_name={user?.institutionName}
						addCourses={handleAddCourses}
					/>
				</div>
			</div>
		</Modal>
	);

	return (
		<div className="body-container">
			<h1>Search for folders in {user?.institutionName}</h1>

			<FindCourseForm
				addCourses={handleSearchCourses}
				institutionNameSlug={institutionNameSlug}
			/>

			{noCoursesFound && (
				<div>
					<h2>
						Can't find your folder? Click on the button bellow to
						create a new folder.
					</h2>
				</div>
			)}

			{courses.map((course: CourseType) => {
				return (
					<div key={course.code}>
						<Course course={course} />
					</div>
				);
			})}

			<button
				onClick={() => setModalIsOpen(true)}
				className="add-material"
			>
				Create Folder
			</button>
			{modal}
		</div>
	);
}
