import { ReactElement, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import {
  CourseListProps,
  MaterialType,
} from "../type/Course.types";
import { Material } from "./Item";

// you would get here with course.id as the slug
// we get to this page from InstitutionCourses or MyCourses
// if we come from InstitutionCourses we dont inherit course so
// we would need to fetch it.
// if we come from MyCourses we need to inherit course.title,
// course.sectionCode, course.couresCode and course.semesterDate
export function CourseList({
  materials,
  setMaterials,
}: CourseListProps): ReactElement | null {
  const { courseId } = useParams();

  useEffect(() => {
    axios
      .get(`/courses/${courseId}/materials`)
      .then((res) => {
        const sortedMaterialsByDate =
          sortMaterialByDate(res.data);
        setMaterials(sortedMaterialsByDate);
      })
      .catch((err) => {
        console.log("err:", err.response);
      });
  }, [courseId]);

  function sortMaterialByDate(materials: MaterialType[]) {
    return materials.sort(
      (a, b) =>
        new Date(a.classDate).getTime() -
        new Date(b.classDate).getTime()
    );
  }

  return (
    <div className="courses-container">
      {materials.map((material) => {
        return (
          <div key={material.id}>
            <Material material={material} />
          </div>
        );
      })}
    </div>
  );
}
