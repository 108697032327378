import { ReactElement, useState } from "react";

import {
	AnswerPostItemProps,
	CommentPostType,
} from "../type/Post.types";

import { Post } from "../Post";
import { Comments } from "../Comments/List";
import { CreatePostForm } from "../Forms/Create";
import { UpdatePostForm } from "../Forms/Update";
import "../styles/answer.css";

export function AnswerItem({
	answer,
	onDelete,
	onUpdate,
}: AnswerPostItemProps): ReactElement | null {
	const [comment, setComment] = useState<CommentPostType>();
	const [showForm, setShowForm] = useState<boolean>(false);
	const [showUpdateForm, setShowUpdateForm] =
		useState<boolean>(false);

	const handleChange = (comment: CommentPostType) => {
		setShowForm(false);
		setComment(comment);
	};

	return (
		<div className="answer-layout">
			{!showUpdateForm && (
				<Post
					post={answer}
					onDelete={onDelete}
					toggleForm={() => {
						setShowUpdateForm(!showUpdateForm);
					}}
				/>
			)}

			{showUpdateForm && (
				<UpdatePostForm
					post={answer}
					toggleForm={() => {
						setShowUpdateForm(!showUpdateForm);
					}}
					onUpdate={onUpdate}
				/>
			)}

			<div className="comments-container">
				<Comments parentPostId={answer.id} comment={comment} />

				{!showForm && (
					<div
						className="add-comment"
						onClick={() => setShowForm(true)}
					>
						Add a comment
					</div>
				)}
			</div>

			{showForm && (
				<CreatePostForm
					parentPostId={answer.id}
					name={"comment"}
					onChange={handleChange}
				/>
			)}
		</div>
	);
}
