import { ReactElement } from "react";
import { Link } from "react-router-dom";
import { CourseProps } from "../type/Course.types";

export function Course({
	course,
}: CourseProps): ReactElement | null {
	return (
		<Link to={`/courses/${course.id}`} className="link">
			<div className="my-courses-card">
				{/* <div className="course-section non-course-code"> */}
					{/* Section {course.sectionCode} */}
				{/* </div> */}
{/*  */}
				{/* <div className="course-code non-course-code"> */}
					{/* {course.code} */}
				{/* </div> */}

				<div className="course-title">{course.title}</div>

				{/* <div className="course-semester non-course-code"> */}
					{/* {course.semesterDate} */}
				{/* </div> */}
			</div>
		</Link>
	);
}
