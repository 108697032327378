export const CalculateInitialOffset = (
	paginationNumber: string | undefined,
	queryParamResponsePageNumber: string | null,
	limit: number
) => {
	var offset;
	// const zeroIndexedPaginationNumber: number = sanitizePaginationNumber(paginationNumber)
	// const newOffset = (zeroIndexedPaginationNumber * LIMIT) % totalPageCount;
	if (typeof paginationNumber !== "undefined") {
		// C2, C3: paginationNumber is defined,
		// queryParamResponsePageNumber is either defined or
		// undefined
		const zeroIndexedPaginationNum = sanitizePaginationNumber(paginationNumber);
		// offset = (zeroIndexedPaginationNum * limit) % totalPageCount;
		offset = zeroIndexedPaginationNum * limit;
		return offset;
	} else if (typeof queryParamResponsePageNumber === null) {
		// C1: paginationNumber is undefined
		// queryParamResponsePageNumber is undefined
		offset = 0;
		return offset;
	}
	// C4: paginationNumber is undefined
	// queryParamResponsePageNumber is defined
	// Note: queryParamResponsePageNumber is 0 indexed
	const zeroIndexedPaginationNum = (Math.floor(Number(queryParamResponsePageNumber) / limit));
	// offset = (zeroIndexedPaginationNum * limit) % totalPageCount;
	// const paginationNumber = (Math.floor(Number(queryParamResponsePageNumber) / limit));
	offset = zeroIndexedPaginationNum * limit;
	// every offset it a multiple of limit 
	// 
	return offset;
};

function sanitizePaginationNumber(paginationNumber: string | undefined) {
	if (typeof paginationNumber === "undefined") {
		return 0;
	}
	return isNumeric(paginationNumber!) ? Number(paginationNumber) - 1 : 0;
}

function isNumeric(str: string) {
	if (typeof str != "string") return false; // we only process strings!
	return !isNaN(str) && !isNaN(parseFloat(str));
}

// cases
// C1
//queryParamResponsePageNumber = undefined && paginationNumber is undefind
//    then go to default paginationNumber of 0

// C2
//queryParamResponsePageNumber = undefined && paginationNumber is defined
//    then go to default paginationNumber of num

// C3
//queryParamResponsePageNumber is defined =  && paginationNumber is defined
//    this will never happen since the navLink will not have
//    paginationNumber in its url

// C4
//queryParamResponsePageNumber = defined && paginationNumber is undefined
//    then need to figure out what will be
//    initialPaginationNumber
// http://localhost:3001/material/3317ea89-e6e6-4d9f-95fa-331f6fbdd668?page-number=1&forum-id=77dd8227-485a-4766-b43b-1f82b5edd61f
