import { ReactElement, useState } from "react";
import axios from "axios";
import { useForm, SubmitHandler } from "react-hook-form";
import { CourseSearchValues } from "../type/Course.types";
import { pickBy, isEmpty } from "lodash";

export function FindCourseForm({
	addCourses,
	institutionNameSlug,
}: any): ReactElement | null {
	const { register, handleSubmit, reset } =
		useForm<CourseSearchValues>();
	const [submitting, setSubmitting] = useState<boolean>(false);
	const [errors, setErrors] = useState<string[]>([]);

	const sanitizeFormData = (data: any) => {
		const sanitizedValues = pickBy(
			data,
			(value) => value.length > 0
		);
		return sanitizedValues;
	};

	const onSubmit: SubmitHandler<CourseSearchValues> = (data) => {
		setErrors([]);
		const sanitizedData = sanitizeFormData(data);
		if (isEmpty(sanitizedData)) {
			setErrors(["At least one field is required."]);
			return;
		}

		setSubmitting(true);
		axios
			.get(
				`/institutions/${institutionNameSlug}/courses?q=${sanitizedData.title}`
			)
			.then((res) => {
				addCourses(res.data);
				reset();
			})
			.catch((err) => {
				console.log("err:", err.response.data.message);
			});
		setSubmitting(false);
	};

	return (
		<>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div>
					<label>Title</label>
					<input {...register("title")} />
				</div>
				<div>
					<button disabled={submitting}>Find my folders!</button>
				</div>
			</form>

			<div>{errors}</div>
		</>
	);
}
