import { ReactElement} from "react";
import { useAuth } from "../../components/Auth/contexts/UserContext";
import { Navigate } from "react-router-dom";

export function RequireAuth( { children }: any): ReactElement | null {
	const {user} = useAuth();

	if (!user) {
		return <Navigate to='/login'/>
	}
	return children
}
