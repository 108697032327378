import { ReactElement, useState } from "react";
import { EmailPreferrences } from "./Settings/EmailPreferrences";

const components = [
	{
		name: "email_preferrences",
		displayName: "Email Preferrences",
		component: <EmailPreferrences />,
	},
];

export function Settings(): ReactElement | null {
	const [highlightedTag, setHighlightedTag] = useState<string>("email_preferrences");
	const [component, setComponent] = useState(<EmailPreferrences />);

	const handleClick = (component: any) => {
		setHighlightedTag(component.name);
		setComponent(component.component);
	};

	return (
		<div>
			<div className="basic-card-container">
				<div className="horizontal-flex-container">
					{components.map((component: any) => {
						return (
							<div
								className={
									"item" +
									(highlightedTag == component.name
										? " highlight"
										: "")
								}
								onClick={() => handleClick(component)}
								key={component.name}
							>
								{component.displayName}
							</div>
						);
					})}
				</div>

				{component}
			</div>
		</div>
	);
}
