import {
	ReactElement,
	useContext,
	useEffect,
	useState,
	useRef,
} from "react";
import axios from "axios";
import { useSearchParams } from "react-router-dom";

import { UserContext } from "../../components/Auth/contexts/UserContext";
import { PostProps } from "./type/Post.types";
import { Vote } from "../Vote/Vote";
import { TimeAgo } from "../../utils/TimeAgo";
import "./styles/post.css";

export function Post({
	post,
	onDelete,
	toggleForm,
}: PostProps): ReactElement | null {
	const [higlightStatus, setHiglightStatus] = useState<
		"" | "is-highlighted" | "was-highlighted"
	>("");
	const [postVerb, setPostVerb] = useState<string>("Asked");
	const { user } = useContext(UserContext);
	const highlightedPost = useRef(null);

	const [searchParams, _] = useSearchParams();

	const timeAgo = TimeAgo(post.createdAt);
	const isAuthor = user?.name == post.userName ? true : false;

	useEffect(() => {
		determinePostVerb(post);
		if (searchParams.get("response-id") == post.id && !searchParams.get("comment-id")) {
			//only response id exists
			setHiglightStatus("is-highlighted");
			return;
		} else if (searchParams.get("comment-id") == post.id) {
			// comment id exists
			setHiglightStatus("is-highlighted");
			return;
		}
	}, [searchParams.get("response-id")]);

	const handleMouseOver = () => {
		if (higlightStatus == "is-highlighted") {
			setHiglightStatus("was-highlighted");
		}
	};

	const determinePostVerb = (post: any) => {
		if (post.name == "answer") {
			setPostVerb("Answered")
		} else if (post.name == "comment"){
			setPostVerb("Commented")
		}
	};

	const handleDelete = () => {
		axios
			.delete(`/posts/${post.id}`)
			.then(onDelete(post.id))
			.catch((err) => {
				console.log(
					"err.response.data.message:",
					err.response.data.message
				);
			});
	};

	return (
		<>
			<div
				onMouseOver={handleMouseOver}
				className={higlightStatus}
				ref={highlightedPost}
			>
				<div className="post-layout">
					<Vote post={post} />

					<div className="post-container">
						<div className="content">{post.content}</div>
						<div className="info">
							{postVerb} by {post.userName} {timeAgo}{" "}
						</div>

						{isAuthor && (
							<div className="actions-container">
								<div onClick={toggleForm}>Edit</div>
								<div onClick={handleDelete}>Delete</div>
							</div>
						)}
					</div>
				</div>
			</div>
		</>
	);
}
