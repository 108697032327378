import { ReactElement, useEffect, useState } from "react";
import axios from "axios";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faCaretUp,
	faCaretDown,
} from "@fortawesome/free-solid-svg-icons";

import { VoteProps, VoteType, IVote } from "./type/Vote.types";
import "./styles/vote.css";

const voteNameValues = { up: 1, down: -1, samosa: 2 };

export function Vote({ post }: VoteProps): ReactElement | null {
	const [voteType, setVoteType] = useState<VoteType | null>(
		null
	);
	const [voteCount, setVoteCount] = useState<number>(post.score);

	useEffect(() => {
		axios
			.get(`/posts/${post.id}/votes`)
			.then((res) => {
				const vote: IVote = res.data;
				if (vote.id) {
					setVoteType(vote.name);
				}
			})
			.catch(() => {});
	}, [post.id]);

	const handleClick = (voteName: VoteType) => {
		const data = { name: voteName };
		axios
			.post(`/posts/${post.id}/votes`, data)
			.then((res) => {
				const vote: IVote = res.data;
				if (voteType == null) {
					//created
					setVoteCount(() => voteCount + vote.value);
					setVoteType(vote.name);
				} else if (vote.id) {
					//updated
					setVoteCount(() => voteCount + 2 * vote.value);
					setVoteType(vote.name);
				} else if (res.data.message == "entity deleted") {
					setVoteCount(
						() => voteCount - voteNameValues[voteName]
					);
					setVoteType(null);
				}
			})
			.catch(() => {});
	};

	return (
		<div
			className={
				post.name == "comment"
					? "vote-container-comment"
					: "vote-container"
			}
		>
			<FontAwesomeIcon
				icon={faCaretUp}
				className={
					voteType == "up"
						? "default-vote-up vote-up"
						: "default-vote-up"
				}
				onClick={() => handleClick("up")}
			/>

			<div className="count">{voteCount}</div>

			<FontAwesomeIcon
				icon={faCaretDown}
				className={
					voteType == "down"
						? "default-vote-down vote-down"
						: "default-vote default-vote-down"
				}
				onClick={() => handleClick("down")}
			/>
		</div>
	);
}
