export function TimeAgo(createdAt: string) {
	const HOUR_UNIT_IN_MILLISECONDS = 3600 * 1000;
	const MINUTE_UNIT_IN_MILLISECONDS = 60 * 1000;
	const diffInMilliseconds =
		new Date().getTime() - new Date(createdAt).getTime();

	const diffInMinutes = Math.round(
		diffInMilliseconds / MINUTE_UNIT_IN_MILLISECONDS
	);

	const diffInHours = Math.round(
		diffInMilliseconds / HOUR_UNIT_IN_MILLISECONDS
	);

	const diffInDays = Math.round(diffInHours / 24);

	let timeAgo;
	if (diffInHours < 1) {
		timeAgo = diffInMinutes + " minutes ago";
	} else if (diffInHours == 1) {
		timeAgo = diffInHours + " hour ago";
	} else if (diffInHours > 1 && diffInHours < 24) {
		timeAgo = diffInHours + " hours ago";
	} else if (diffInHours >= 24 && diffInHours <= 48) {
		timeAgo = diffInDays + " day ago";
	} else {
		const diffInDays = Math.round(diffInHours / 24);
		timeAgo = diffInDays + " days ago";
	}
	return timeAgo;
}
