import { ReactElement, useState, useContext } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import { UserContext } from "../../components/Auth/contexts/UserContext";

type FormValues = {
	password: string;
};

type UserData = {
	user: {
		password: string;
		password_confirmation: string;
		reset_password_token: string;
	};
};

export function ResetPassword(): ReactElement | null {
	const [serverErrors, setServerErrors] = useState<string>();
	const [submitting, setSubmitting] = useState<boolean>(false);
	const userContext = useContext(UserContext);

	const navigate = useNavigate();
	const [searchParams, _] = useSearchParams();

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<FormValues>({
		defaultValues: {
			password: "Password",
		},
	});

	const onSubmit: SubmitHandler<FormValues> = (data) => {
		const reset_password_token = searchParams.get(
			"reset_password_token"
		);
		const userData = {
			user: {
				password: data.password,
				password_confirmation: data.password,
				reset_password_token: reset_password_token!,
			},
		};
		putData(userData);

		// TODO: automatically sign them in //

		setSubmitting(false);
	};

	const putData = (data: UserData) => {
		const config = {
			headers: {
				"Content-Type": "application/json",
				Accept: "application/html",
			},
		};
		axios
			.put("/users/password", data, config)
			.then((res) => {
				const user = res.data;
				userContext.setUser(user);
				navigate("/courses/my-courses");
			})
			.catch((err) => {
				setServerErrors(err.response.data.message);
			});
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className={"mt-2"}>
				<label>Please enter a new password </label>
				<input
					type="password"
					{...register("password", {
						required: "You must specify a password",
						minLength: {
							value: 8,
							message: "min length is 8",
						},
					})}
					className={"ml-2"}
				/>
				{errors.password && <p>{errors.password.message}</p>}
			</div>

			{serverErrors && (
				<p className="form-error">{serverErrors}</p>
			)}

			<div className={"mt-2"}>
				<button
					type="submit"
					disabled={submitting}
					className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 mt-2 rounded text-xs"
				>
					Submit
				</button>
			</div>
		</form>
	);
}
