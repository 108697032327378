import { ReactElement, useState, useEffect } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";

import {
	CreatePostFormProps,
	PostFormType,
} from "../type/Post.types";

export function CreatePostForm({
	parentPostId,
	name,
	onChange,
}: CreatePostFormProps): ReactElement | null {
	const [submitting, setSubmitting] = useState<boolean>(false);
	const [addPostStatement, setAddPostStatement] =
		useState<string>();

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<PostFormType>({});

	useEffect(() => {
		if (name == "comment") {
			setAddPostStatement("Add a comment");
		} else {
			setAddPostStatement("Add an answer");
		}
	}, []);

	const onSubmit = (data: PostFormType) => {
		data.name = name;
		axios
			.post(`/posts/${parentPostId}/responses`, data)
			.then((res) => {
				onChange(res.data);
				reset();
			})
			.catch((err) => {
				console.log(
					"err.response.data.message:",
					err.response.data.message
				);
			});

		setSubmitting(false);
	};

	const handleCancel = () => {
		onChange();
		reset();
	};

	return (
		<>
			<h4>{addPostStatement}</h4>
			<form
				onSubmit={handleSubmit((data) => onSubmit(data))}
				className="post-form-container"
			>
				<textarea
					{...register("content", {
						required: true,
					})}
					className="post-form-textarea-content"
				/>
				{errors.content && <p>{errors.content.message}</p>}

				<div className="actions-container">
					<input
						type="submit"
						disabled={submitting}
						value="Submit"
					/>
					{name == "comment" && (
						<input
							type="button"
							onClick={handleCancel}
							value="Cancel"
						/>
					)}
				</div>
			</form>
		</>
	);
}
