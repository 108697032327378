import { ReactElement, useState, useEffect } from "react";
import axios from "axios";
import { Course } from "./Item";
import { CourseType } from "../type/Course.types";

export function MyCoursesList(): ReactElement | null {
	const [courses, setCourses] = useState<CourseType[]>([]);

	useEffect(() => {
		axios
			.get("/users/courses")
			.then((res) => {
				setCourses(res.data);
			})
			.catch((err) => {
				console.log("err:", err.response);
			});
	}, []);

	return (
		<div className="my-courses-container">
			{courses.map((course: CourseType) => {
				return <Course course={course} key={course.id} />;
			})}
		</div>
	);
}
