import {
	ForumPostType,
	ResponsePostType,
	AccountPostType,
} from "../components/Account/type/Post";

export const CreateForumQueryParam = (
	post: ForumPostType | ResponsePostType
) => {
	const basePath: string = [
		"/material/",
		post.materialId,
		"?",
	].join("");

	if (isForum(post)) {
		var queryParams = createForumBaseQueryParams(
			post as ForumPostType
		);
	} else {
		var queryParams = createResponseBaseQueryParams(
			post as ResponsePostType
		);
	}
	const navLink = basePath.concat(queryParams);

	return navLink;
};

const isForum = (post: AccountPostType) => {
	const postType = {
		question: true,
		typo_correction: true,
		note: true,
		answer: false,
		comment: false,
	};
	return postType[post.name];
};

const createForumBaseQueryParams = (post: ForumPostType) => {
	// ex: // http://localhost:3001/material/{materialId}?
	// page-number={page-number}&forum-id={forumId}
	var baseQueryParams: string[] = [
		`page-number=${post.pageNumber}`,
		`forum-id=${post.id}`,
	];
	const queryParams = baseQueryParams.join("&");
	return queryParams;
};

const createResponseBaseQueryParams = (
	post: ResponsePostType
) => {
	// ex: // http://localhost:3001/material/{materialId}?
	// page-number={page-number}&forum-id={forumId}
	// &post-id={postId}&comment-id={commentId}
	var baseQueryParams: string[] = [
		`page-number=${post.pageNumber}`,
		`forum-id=${post.forumId}`,
	];

	if (post.name == "comment") {
		const postId = post.parentId;
		const commentId = post.id;
		const commentQueryParam = `comment-id=${commentId}`;
		const postQueryParam = `response-id=${postId}`;
		baseQueryParams.push(postQueryParam, commentQueryParam);
	} else {
		const postId = post.id;
		const postQueryParam = `response-id=${postId}`;
		baseQueryParams.push(postQueryParam);
	}

	const queryParams = baseQueryParams.join("&");
	return queryParams;
};
