import { ReactElement, useState, useEffect } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";

import {
	UpdatePostFormType,
	UpdatePostProps,
} from "../type/Post.types";

export function UpdatePostForm({
	toggleForm,
	post,
	onUpdate,
}: UpdatePostProps): ReactElement | null {
	const [submitting, setSubmitting] = useState<boolean>(false);

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<UpdatePostFormType>({});

	useEffect(() => {
		const defaultValues = {
			content: post.content,
			title: post.title,
		};
		reset(defaultValues);
	}, []);

	const onSubmit = async (data: UpdatePostFormType) => {
		await axios
			.patch(`/posts/${post.id}`, data)
			.then((res) => {
				reset();
				toggleForm();
				const post = res.data;
				onUpdate(post);
			})
			.catch((err) => {
				console.log(
					"err.response.data.message:",
					err.response.data.message
				);
			});

		setSubmitting(false);
	};

	return (
		<>
			<form
				onSubmit={handleSubmit((data) => onSubmit(data))}
				className="post-form-container"
			>
				{post.title && (
					<>
						<textarea
							{...register("title", {
								required: true,
							})}
							className="post-form-textarea-title"
						/>
						{errors.content && <p>{errors.content.message}</p>}
					</>
				)}

				<textarea
					{...register("content", {
						required: true,
					})}
					className="post-form-textarea-content"
				/>
				{errors.content && <p>{errors.content.message}</p>}

				<div>
					<input
						type="submit"
						disabled={submitting}
						value="Submit"
					/>
					<input
						type="button"
						onClick={toggleForm}
						value="Cancel"
					/>
				</div>
			</form>
		</>
	);
}
