import { ReactElement, useRef, useState, useEffect, useCallback } from "react";
import axios from "axios";
import { pdfjs, Document, Page } from "react-pdf";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { MaterialPageType, MaterialPageProps } from "./type/Material.types";
import { Forum } from "../Forum/Forum";
import { CalculateInitialOffset } from "../../utils/CalculateInitialOffset";

import { useSearchParams, useParams } from "react-router-dom";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export function MaterialPages({
	materialId,
	limit,
}: MaterialPageProps): ReactElement | null {
	const [materialPages, setMaterialPages] = useState<MaterialPageType[]>([]);
	const [searchText, setSearchText] = useState("");
	const refs = useRef(Array(limit).fill(null));

	const { paginationNumber } = useParams();
	const [searchParams, _] = useSearchParams();
	const queryParamResponsePageNumber = searchParams.get("page-number");
	const queryParamParagraphId = searchParams.get("paragraph-id");

	useEffect(() => {
		const offset = CalculateInitialOffset(
			paginationNumber,
			queryParamResponsePageNumber,
			limit
		);
		axios
			.get(`/materials/${materialId}/pages?offset=${offset}&limit=${limit}`)
			.then((res) => {
				const sortedPagesByNumber = sortPagesByNumber(res.data);
				setMaterialPages(sortedPagesByNumber);
			})
			.catch((err) => {
				console.log("err:", err);
			});
	}, [materialId, paginationNumber]);

	useEffect(() => {
		if (queryParamParagraphId) {
			axios
				.get(`/paragraphs/${queryParamParagraphId}`)
				.then((res) => {
					setSearchText(res.data.paragraph)
				})
				.catch((err) => {
					console.log("err:", err);
				});
		}
	}, [queryParamParagraphId]);

	const textRenderer = useCallback(
		(textItem) => highlightPattern(textItem.str, searchText),
		[searchText]
	);

	function highlightPattern(text:any, pattern:any) {
		return text.replace(pattern, (value:any) => `<mark>${value}</mark>`);
	}

	function sortPagesByNumber(pages: MaterialPageType[]) {
		return pages.sort((a, b) => a.number - b.number);
	}

	const scrollToMaterialPage = (currentIndex: number) => {
		if (queryParamResponsePageNumber && materialPages.length) {
			const i = Number(queryParamResponsePageNumber) - materialPages[0].number;
			if (currentIndex === i) {
				const el: HTMLDivElement = refs.current[i];
				window.scrollTo({
					top: el.offsetTop,
					behavior: "smooth",
				});
			}
		}
	};

	return (
		<>
			{materialPages.map((materialPage: MaterialPageType, i: number) => {
				return (
					<div
						className="material-container"
						key={materialPage.id}
						ref={(el) => {
							refs.current[i] = el;
						}}
						id={materialPage.id}
					>
						<Document file={materialPage.url}>
							<Page
								pageNumber={1}
								onRenderSuccess={() => {
									scrollToMaterialPage(i);
								}}
								customTextRenderer={textRenderer}
							/>
						</Document>

						<div className="forum">
							<Forum
								pageNumber={materialPage.number}
								materialId={materialId!}
								queryParams={searchParams}
								offset={limit}
							/>
						</div>
					</div>
				);
			})}
		</>
	);
}
