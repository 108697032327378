import { Header } from "./Header";

const Layout = ({ children }: any) => {
	return (
		<div>
			<div>
				<Header />
				<main>{children}</main>
			</div>
		</div>
	);
};

export default Layout;
