import { ReactElement, useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import Modal from "react-modal";
import {
	MaterialType,
	CourseType,
} from "../../components/Course/type/Course.types";
import { UploadMaterial } from "../../components/Course/Course/UploadMaterial";
import { CourseList } from "../../components/Course/Course/List";
import "../../styles/course.css";

Modal.setAppElement("#root");
// you would get here with course.id as the slug
// we get to this page from InstitutionCourses or MyCourses
// if we come from InstitutionCourses we dont inherit course so
// we would need to fetch it.
// if we come from MyCourses we need to inherit course.title,
// course.sectionCode, course.couresCode and course.semesterDate
export function Course(): ReactElement | null {
	const [materials, setMaterials] = useState<MaterialType[]>([]);
	const [course, setCourse] = useState<CourseType | null>(null);
	const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
	const { courseId } = useParams();

	useEffect(() => {
		axios
			.get(`/courses/${courseId}`)
			.then((res) => {
				const data = res.data;
				// const course: CourseType = {
					// id: data.id,
					// code: data.code,
					// sectionCode: data.sectionCode,
					// semesterDate: data.semesterDate,
					// title: data.title,
					// instructorName: data.instructor_name
				// }
				setCourse(res.data);
			})
			.catch((err) => {
				console.log("err:", err.response);
			});
	}, [courseId]);

	const handleAddMaterial = (material: MaterialType) => {
		setMaterials((oldMaterials) => [...oldMaterials, material]);
	};

	return (
		<>
			<div className="my-courses-header-container">
				{/* <h1>{course?.semesterDate} {course?.code}-{course?.sectionCode} : {course?.title}</h1> */}
				<h1>{course?.title}</h1>
			</div>

			<div className="my-courses-header-container">
				<h2>Available Documents</h2>
			</div>

			<CourseList
				materials={materials}
				setMaterials={setMaterials}
			/>

			<button
				onClick={() => setModalIsOpen(true)}
				className="add-material"
			>
				Add document
			</button>

			<Modal
				isOpen={modalIsOpen}
				onRequestClose={() => {
					setModalIsOpen(false);
				}}
			>
				<div className="modal-container">
					<div className="action-title">
						Upload Document
					</div>

					<div className="close-modal">
						<button onClick={() => setModalIsOpen(false)}>
							Close
						</button>
					</div>

					<div className="upload-material">
						<UploadMaterial
							courseId={courseId}
							materials={materials}
							addMaterial={handleAddMaterial}
							closeModal={() => setModalIsOpen(false)}
						/>
					</div>
				</div>
			</Modal>
		</>
	);
}
