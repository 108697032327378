import { ReactElement, useState, useContext, useEffect } from "react";
import axios from "axios";

import { ForumType, ForumProps } from "./Forum.types";
import { ForumItem } from "./Item";
import { UserContext } from "../../components/Auth/contexts/UserContext";

export function List({
	pageNumber,
	materialId,
	forumsFilterName,
	forum,
  offset
}: ForumProps): ReactElement | null {
	const { user } = useContext(UserContext);
	const [forums, setForums] = useState<ForumType[]>([]);

	useEffect(() => {
		axios
			.get(`/materials/${materialId}/pages/${pageNumber}/posts`)
			.then((res) => {
				var forums = res.data;
				setForums(forums);
			})
			.catch((err) => {
			});
	}, [pageNumber]);

	useEffect(() => {
		const newForum: ForumType = forum!;
		if (typeof newForum === "undefined") {
			return;
		}
		setForums((currentForums) => [...currentForums, newForum]);
	}, [forum]);

	const handleDeleteForum = (forumId: string) => {
		setForums(forums.filter((forum) => forum.id !== forumId));
	};

	const handleUpdateForum = (index: number) => (forum: ForumType) => {
		let newForums = [...forums];
		newForums[index] = forum;
		setForums(newForums);
	};

	return (
		<>
			{forums.map((forum: ForumType, index: number) => {
				if (
					forumsFilterName == "all"
					|| forum.name == forumsFilterName
					|| forum.userName == user!.name
				) {
					return (
						<ForumItem
							forum={forum}
							onDelete={handleDeleteForum}
							onUpdate={handleUpdateForum(index)}
							key={forum.id}
              offset={offset}
						/>
					);
				}
			})}
		</>
	);
}
