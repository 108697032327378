import { ReactElement, useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import "../../styles/account.css";
import { Forums } from "../../components/Account/Forums";
import { Responses } from "../../components/Account/Responses";
import { Activity } from "../../components/Account/Activity";
import { Settings } from "../../components/Account/Settings";
import { UserContext } from "../../components/Auth/contexts/UserContext";

const components = {
	activities: {
		component: <Activity />,
		displayName: "Activity",
	},
	forums: {
		component: <Forums />,
		displayName: "Forums",
	},
	responses: {
		component: <Responses />,
		displayName: "Responses",
	},
	settings: {
		component: <Settings />,
		displayName: "Settings",
	},
};

export function Account(): ReactElement | null {
	const { user } = useContext(UserContext);
	const { hash } = useLocation();
	var defaultHighlightedTag = "activities";
	if (hash === "#settings") {
		defaultHighlightedTag = "settings";
	}
	const [highlightedTag, setHighlightedTag] = useState<string>(
		defaultHighlightedTag
	);
	const [component, setComponent] = useState(
		components[defaultHighlightedTag].component
	);

	const handleClick = (component: any) => {
		setHighlightedTag(component.name);
		setComponent(component.component);
	};

	return (
		<div>
			<div className="account-header">
				<h2>Hi {user?.name}</h2>
			</div>
			<div className="horizontal-flex-container">
				{Object.keys(components).map((key: any) => {
					const component = components[key];
					return (
						<div
							className={"item" + (highlightedTag == key ? " highlight" : "")}
							onClick={() => handleClick(component)}
							key={key}
						>
							{component.displayName}
						</div>
					);
				})}
			</div>

			{component}
		</div>
	);
}
