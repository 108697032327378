import { ReactElement, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import axios from "axios";

type FormValues = {
	email: string;
};

export function ConfirmEmail(): ReactElement | null {
	const [serverErrors, setServerErrors] = useState<string>();
	const [emailSent, setEmailSent] = useState<boolean>(false);
	const [email, setEmail] = useState<string>();
	const [submitting, setSubmitting] = useState<boolean>(false);

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<FormValues>();

	const onSubmit: SubmitHandler<FormValues> = (data) => {
		const userData = { user: { email: data.email } };
		axios
			.post("/users/password", userData)
			.then(() => {
				//notify that email was sent
				setEmail(data.email);
				setEmailSent(true);
			})
			.catch((err) => {
				setServerErrors(err.response.data);
			});
		setSubmitting(false);
	};

	return (
		<div>
			{!emailSent && (
				<form onSubmit={handleSubmit(onSubmit)}>
					<h2>Forgot Password</h2>
					<div>
						If you've forgotten your password, enter your Martlet
						email here and we'll send you a reset link.
					</div>
					<div>
						<label>Email address {" "}</label>
						<input
							{...register("email", {
								required: true,
								pattern: {
									value: /^\S*$/,
									message:
										"Invalid email. Please do not use spaces",
								},
							})}
						/>
						{errors.email && <p>{errors.email.message}</p>}
					</div>

					{serverErrors && (
						<p className="form-error">{serverErrors}</p>
					)}

					<div className={"mt-2"}>
						<button type="submit" disabled={submitting}>
							Submit
						</button>
					</div>
				</form>
			)}
			{emailSent && (
				<p>
					Password reset link emailed to {email}. Please check
					that email and click on the link to reset password.
				</p>
			)}
		</div>
	);
}
