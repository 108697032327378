import { ReactElement, useState, useEffect } from "react";

import { AnswerPostType, PostsProps } from "./type/Post.types";

import { OriginalPost } from "./OriginalPost";
import { Answers } from "./Answers/List";
import { CreatePostForm } from "./Forms/Create";

export function Posts({
	forum,
	onDelete,
	onUpdate,
}: PostsProps): ReactElement | null {
	const [originalPost, setOriginalPost] = useState(forum);
	const [answer, setAnswer] = useState<AnswerPostType>();

	useEffect(() => {
		setOriginalPost(forum);
	}, [forum]);

	return (
		<div className="posts-layout">
			<OriginalPost
				post={originalPost}
				onDelete={onDelete}
				onUpdate={onUpdate}
			/>

			<h4>Answers</h4>
			<Answers
				parentPostId={originalPost.id}
				answer={answer}
			/>

			<CreatePostForm
				parentPostId={originalPost.id}
				name={"answer"}
				onChange={setAnswer}
			/>
		</div>
	);
}
