import { ReactElement } from "react";
import { Link } from "react-router-dom";
import "../../styles/home.css";

type FeatureType = {
	title: string;
	body: string;
	image: string;
};

const discussion: FeatureType = {
	title: "Martlet helps parents with their children's homework",
	body: "See what other students or parents are discussing.",
	image: require("./images/copy.png"),
};

const features: FeatureType[] = [
	{
		title: "Ask Questions",
		body: "Parents and students can ask questions about the homework for help or clarification.",
		image: require("./images/ask_question.png"),
	},
	{
		title: "Get Answers",
		body: "Get and give answers to other parents and students questions.",
		image: require("./images/give_answer.png"),
	},
	{
		title: "Share Tips",
		body: "Share tips with other parents or students.",
		image: require("./images/share_tips.png"),
	},
	{
		title: "Search",
		body: "Quickly search for questions and answers.",
		image: require("./images/search.png"),
	},
];

export function HomePage(): ReactElement | null {
	return (
		<div>
			<div className="header-container">
				<div className="feature-description">
					<h2 className="title">
						{discussion.title}
					</h2>
					<div className="header-body">
						Ask and answer questions in the margins of your child's homework
						or class handouts.
					</div>

					<div className="header-body">
						Discuss solutions and tips with other parents and students.
					</div>

					<div className="header-body">
						Want to learn more? Email me at&nbsp;
						<a href="mailto:marcos@martlet.io?subject=Request%20for%20more%20information">
							marcos@martlet.io
						</a>
						&nbsp;and I will get back to you.
					</div>
				</div>

				<div className="feature-image">
					<img src={discussion.image} alt="" />
				</div>
			</div>

			<div className="wave">
				<svg
					data-name="Layer 1"
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 1200 120"
					preserveAspectRatio="none"
				>
					<path
						d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
						className="shape-fill"
					></path>
				</svg>
			</div>

			{features.map((feature: FeatureType) => {
				return (
					<div className="feature-container">
						<div className="feature-description">
							<h2>{feature.title}</h2>
							<div>{feature.body}</div>
						</div>
						<div className="feature-image">
							<img src={feature.image} alt="" />
						</div>
					</div>
				);
			})}
		</div>
	);
}
