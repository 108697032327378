import { ReactElement, useState, useEffect } from "react";
import Modal from "react-modal";
import { ForumItemProps } from "./Forum.types";
import { Posts } from "../Post/Posts";
import { useSearchParams, useLocation, useNavigate } from "react-router-dom";

Modal.setAppElement("#root");

export function ForumItem({
	forum,
	onDelete,
	onUpdate,
	offset
}: ForumItemProps): ReactElement | null {
	const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
	const [tagClassName, setTagClassName] = useState<string>();
	const [tagDisplayName, setTagDisplayName] = useState<string>();
	const [queryParams, setQueryParams] =
		useState<URLSearchParams>();

	const [searchParams, _] = useSearchParams();
	const { pathname } = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		if (searchParams.get("forum-id") == forum.id) {
			setModalIsOpen(true);
			setQueryParams(searchParams);
		}
		setForumTagClassName(forum.name);
	}, [searchParams.get("forum-id")]);

	const setForumTagClassName = (name: string) => {
		if (name == "typo_correction") {
			setTagClassName("typo");
			setTagDisplayName("Typo Correction");
		} else {
			setTagClassName(name);
			setTagDisplayName(name);
		}
	};

	const handleDeleteOriginalPost = (answerId: string) => {
		// use onDelete here and then close the modal
		onDelete(answerId);
		setModalIsOpen(false);
	};

	const handleClick = () => {
		setModalIsOpen(true);
	};

	const handleRequestClose = () => {
		setModalIsOpen(false);
		if (searchParams.get("forum-id")) {
			setQueryParams(searchParams);
			const queryParamResponsePageNumber = searchParams.get("page-number")
			const zeroIndexedPaginationNum = (Math.floor(Number(queryParamResponsePageNumber) / offset!));
			navigate(`${pathname}/${zeroIndexedPaginationNum + 1}`);
			for (const key of Array.from(searchParams.keys())) {
				searchParams.delete(key);
			}
		}
	};

	return (
		<>
			<div className="forum-card" onClick={handleClick}>
				<div className="score">{forum.score}</div>

				<div className="header">
					<h3>{forum.title}</h3>
				</div>
				<div className="content">{forum.content}</div>
			</div>

			<Modal
				isOpen={modalIsOpen}
				id="forum-item-modal"
				onRequestClose={handleRequestClose}
				className="modal-content"
				overlayClassName="Overlay"
			>
				<div className="forum-modal">
					<div className="close-modal">
						<button onClick={handleRequestClose}>Close</button>
					</div>
					<div>
						<Posts
							forum={forum}
							onDelete={handleDeleteOriginalPost}
							onUpdate={onUpdate}
							setModalIsOpen={setModalIsOpen}
							queryParams={queryParams}
						/>
					</div>
				</div>
			</Modal>
		</>
	);
}
