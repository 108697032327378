import { ReactElement, useState, useEffect } from "react";
import axios from "axios";

export function EmailPreferrences(): ReactElement | null {
	const [receiveEmails, setReceiveEmails] = useState<boolean>(false);

	useEffect(() => {
		axios
			.get("/users/emails")
			.then((res) => {
				setReceiveEmails(res.data.subscribed);
			})
			.catch(() => {});
	}, []);

	const handleChange = (emailStatus: boolean) => {
		axios
			.put("/users/emails", { email_status: emailStatus })
			.then((res) => {
				setReceiveEmails(res.data.subscribed);
			})
			.catch(() => {});
	};

	return (
		<div>
			<div>Receive Emails</div>
			<div>
				<label>
					<input
						onChange={() => handleChange(true)}
						type="radio"
						name="toggle"
						checked={receiveEmails}
					/>
					<span>Yes</span>
				</label>
				<label>
					<input
						onChange={() => handleChange(false)}
						type="radio"
						name="toggle"
						checked={!receiveEmails}
					/>
					<span>No</span>
				</label>
			</div>
		</div>
	);
}
