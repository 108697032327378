import { ReactElement, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import ReactPaginate from "react-paginate";
import { MaterialType } from "../../components/Material/type/Material.types";
import { MaterialPages } from "../../components/Material/List";
// import { Search } from "../../components/Material/Search";

export function Material(): ReactElement | null {
	const LIMIT = 5;

	const { materialId, paginationNumber } = useParams();
	const sanitizedPaginationNumber = sanitizePaginationNumber(paginationNumber);

	const [totalPageCount, setTotalPageCount] = useState<number>(0);
	const [material, setMaterial] = useState<MaterialType | null>(null);

	const navigate = useNavigate();

	const pageCount = Math.ceil(totalPageCount / LIMIT);

	useEffect(() => {
		axios
			.get(`/materials/${materialId}`)
			.then((res) => {
				const data = res.data;
				setTotalPageCount(data.pageCount);
				setMaterial(data);
			})
			.catch((err) => {
				console.log("err:", err.response);
			});
	}, [materialId]);

	const handlePageClick = (event: any) => {
		navigate(`/material/${materialId}/${event.selected + 1}`);
	};

	function sanitizePaginationNumber(paginationNumber: string | undefined) {
		if (typeof paginationNumber === "undefined") {
			return 0;
		}
		return isNumeric(paginationNumber!) ? Number(paginationNumber) - 1 : 0;
	}

	function isNumeric(str: string) {
		if (typeof str != "string") return false; // we only process strings!
		return !isNaN(str) && !isNaN(parseFloat(str));
	}

	return (
		<>
			<div className="material-header-container">
				<div className="title">
					<h1>{material?.title}</h1>
				</div>

				<div className="date">
					<h2>{material?.classDate}</h2>
				</div>
			</div>

			{/* <Search */}
				{/* materialId={materialId} */}
				{/* searchPrompt={"Search for questions and answers in this document"} */}
			{/* /> */}

			<MaterialPages
				materialId={materialId}
				limit={LIMIT}
			/>

			<div className="pagination-container">
				<ReactPaginate
					nextLabel="next >"
					forcePage={sanitizedPaginationNumber}
					onPageChange={handlePageClick}
					pageRangeDisplayed={3}
					marginPagesDisplayed={3}
					pageCount={pageCount}
					previousLabel="< previous"
					pageClassName="page-item"
					pageLinkClassName="page-link"
					previousClassName="page-item"
					previousLinkClassName="page-link"
					nextClassName="page-item"
					nextLinkClassName="page-link"
					breakLabel="..."
					breakClassName="page-item"
					breakLinkClassName="page-link"
					containerClassName="pagination"
					activeClassName="active"
				/>
			</div>
		</>
	);
}
