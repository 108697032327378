import { ReactElement, useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from "../../components/Auth/contexts/UserContext";
import { useNavigate } from "react-router-dom";

const authActionStatements = { out: "Logout", in: "Login" };

export function Action(): ReactElement | null {
	const auth = useAuth();
	const [userSignedIn, setUserSignedIn] = useState<boolean>(false);
	const [authAction, setAuthAction] = useState<string>(
		authActionStatements.out
	);

	const navigate = useNavigate();

	useEffect(() => {
		if (auth.user?.name) {
			setUserSignedIn(true);
			setAuthAction(authActionStatements.out);
		} else {
			setUserSignedIn(false);
			setAuthAction(authActionStatements.in);
		}
	}, [auth.user?.name]);

	const logOut = () => {
		axios.delete("/users/sign_out/").then(() => {
			auth.logout(null);
			setUserSignedIn(false);
			setAuthAction(authActionStatements.in);
			navigate("/");
		});
	};

	const logIn = () => {
		navigate("/login");
	};

	const handleClick = () => {
		if (userSignedIn) {
			logOut();
		} else {
			logIn();
		}
	};

	return (
		<>
			<div onClick={handleClick} className="option logout">
				{authAction}
			</div>

			{!userSignedIn && (
				<div onClick={() => navigate("/register")} className="option logout">
					Sign Up
				</div>
			)}
		</>
	);
}
