import { ReactElement, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";

type FormValues = {
	code: string;
	semester_date: string;
	instructor_name: string;
	section_code: string;
	title: string;
	institution_name: string;
};

export function CreateFolder({
	closeModal,
	institution_name,
	addCourses,
}: any): ReactElement | null {
	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<FormValues>({
		defaultValues: {
			code: "folder",
			semester_date: "folder",
			instructor_name: "folder",
			section_code: "folder",
		},
	});

	const [submitting, setSubmitting] = useState<boolean>(false);

	const onSubmit: SubmitHandler<FormValues> = (data) => {
		// setSubmitting(true);
		data["institution_name"] = institution_name;
		console.log("data:", data);
		uploadMaterial(data);
	};

	const uploadMaterial = (data: any) => {
		axios
			.post(`/courses`, data)
			.then((res) => {
				addCourses(res.data);
				reset();
				setSubmitting(false);
				closeModal();
			})
			.catch(() => {
				setSubmitting(false);
			});
	};

	return (
		<div className="courses-container">
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="form-field">
					<input
						id="title"
						placeholder="Folders title"
						{...register("title", {
							required: "You must specify folder title",
						})}
					/>
				</div>

				{errors.title && (
					<li className="form-error">{errors.title.message}</li>
				)}

				<button disabled={submitting}>
					{submitting && <i style={{ marginRight: "5px" }} />}
					{submitting && <span>Adding Folder</span>}
					{!submitting && <span>Add Folder</span>}
				</button>
			</form>
		</div>
	);
}
