import { ReactElement, useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ForumPostType } from "./type/Post";
import { CreateForumQueryParam } from "../../utils/CreateForumQueryParam";
import { TimeAgo } from "../../utils/TimeAgo";

export function Forums(): ReactElement | null {
	const [forums, setForums] = useState([]);
	const navigate = useNavigate();

	useEffect(() => {
		axios.get("/users/posts/forums").then((res) => {
			setForums(res.data);
		});
	}, []);

	const handleClick = (forum: ForumPostType) => {
		const navLink = CreateForumQueryParam(forum);
		navigate(navLink);
	};

	return (
		<>
			{forums.map((forum: ForumPostType) => {
				return (
					<div
						key={forum.id}
						className="basic-card-container account-post-container"
						onClick={() => handleClick(forum)}
					>
						<div className="post-title-container">
							<div className="course">
								{forum.courseCode} &#183; {forum.courseTitle} &#183; {TimeAgo(forum.createdAt)}
							</div>

							<div className="title">{forum.title}</div>
						</div>

						<div className="content">{forum.content}</div>
					</div>
				);
			})}
		</>
	);
}
