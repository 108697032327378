import {
	ReactElement,
	useState,
	useEffect,
	useContext,
} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { UserContext } from "../../components/Auth/contexts/UserContext";

export function Account(): ReactElement | null {
	const { user } = useContext(UserContext);
	const [hasNotification, setHasNotification] =
		useState<boolean>(false);
	const { pathname } = useLocation();

	useEffect(() => {
		if (user?.name) {
			axios.get("/users/notifications/exists").then((res) => {
				const notificationsExist = res.data.exists;
				setHasNotification(notificationsExist);
			});
		}
	}, [pathname]);

	return (
		<div className="notification">
			<span>My Acount</span>
			{hasNotification && (
				<FontAwesomeIcon icon={faBell} className="badge" />
			)}
		</div>
	);
}
