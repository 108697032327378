import { ReactElement, useContext } from "react";
import { Link } from "react-router-dom";
import "../../styles/my-courses.css";
import { UserContext } from "../../components/Auth/contexts/UserContext";
import { MyCoursesList } from "../../components/Course/MyCourses/List";

export function MyCourses(): ReactElement | null {
	const { user } = useContext(UserContext);
	const institutionNameSlug = user?.institutionName
		.toLowerCase()
		.replace(/ /g, "-")
		.replace(/[^\w-]+/g, "");

	return (
		<>
			<div className="my-courses-header-container">
				<h1>My Folders for {user?.name}</h1>
			</div>

			<MyCoursesList />


			<div>
				<Link
					to={`/courses/${institutionNameSlug}/course-codes`}
				>
					<button className="add-more-courses">
						Add Folders
					</button>
				</Link>
			</div>
		</>
	);
}
