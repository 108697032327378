import { ReactElement } from "react";
import { Link } from "react-router-dom";
import { MaterialProps } from "../type/Course.types";

// you would get here with course.id as the slug
// we get to this page from InstitutionCourses or MyCourses
// if we come from InstitutionCourses we dont inherit course so
// we would need to fetch it.
// if we come from MyCourses we need to inherit course.title,
// course.sectionCode, course.couresCode and course.semesterDate
export function Material({
	material,
}: MaterialProps): ReactElement | null {
	return (
		<>
			<Link to={`/material/${material.id}/1`} className="link">
				<div className="basic-card">
					<div className="course-container">
						<div className="date">{material.classDate}</div>

						<div className="title">{material.title}</div>
					</div>
				</div>
			</Link>
		</>
	);
}
