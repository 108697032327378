import { ReactElement, useState, useEffect } from "react";

import { OriginalPostItemProps } from "./type/Post.types";

import { Post } from "./Post";
import "./styles/original.css";
import { UpdatePostForm } from "./Forms/Update";

export function OriginalPost({
	post,
	onDelete,
	onUpdate,
}: OriginalPostItemProps): ReactElement | null {
	const [originalPost, setOriginalPost] = useState(post);
	const [showUpdateForm, setShowUpdateForm] =
		useState<boolean>(false);

	useEffect(() => {
		setOriginalPost(post);
	}, [post]);

	return (
		<div className="original-post-container">
			{!showUpdateForm && (
				<>
					<h3 className="header">{post.title}</h3>
					<Post
						post={originalPost}
						onDelete={onDelete}
						toggleForm={() => {
							setShowUpdateForm(!showUpdateForm);
						}}
					/>
				</>
			)}

			{showUpdateForm && (
				<UpdatePostForm
					post={post}
					toggleForm={() => {
						setShowUpdateForm(!showUpdateForm);
					}}
					onUpdate={onUpdate}
				/>
			)}
		</div>
	);
}
