import { ReactElement, useState, useEffect } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { ResponsePostType } from "./type/Post";
import { CreateForumQueryParam } from "../../utils/CreateForumQueryParam";
import { TimeAgo } from "../../utils/TimeAgo";

const postSentenceType = {
	answer: "replied to your ",
	comment: "commented on your ",
};

export function Activity(): ReactElement | null {
	const [items, setItems] = useState([]);
	const navigate = useNavigate();

	useEffect(() => {
		axios.get("/users/posts/activity").then((res) => {
			setItems(res.data);
		});
	}, []);

	const destroyNotification = (notificationId: string) => {
		if (notificationId) {
			axios.delete(`/users/notifications/${notificationId}`);
		}
	};

	const handleClick = (response: ResponsePostType) => {
		const navLink = CreateForumQueryParam(response);
		navigate(navLink);
		destroyNotification(response.notificationId);
	};

	return (
		<>
			{items.map((response: ResponsePostType) => {
				return (
					<div
						key={response.id}
						className="basic-card-container activity-container"
						onClick={() => handleClick(response)}
					>
						<div className="title">
							<div>
								{response.notificationId && (
									<FontAwesomeIcon icon={faBell} className="badge" />
								)}
							</div>

							<div>
								{response.userName} {postSentenceType[response.name]}
								{response.parentName} in {response.courseCode} &#183;{" "}
								{response.courseTitle} {TimeAgo(response.createdAt)}
							</div>
						</div>

						<div className="content">{response.content}</div>
					</div>
				);
			})}
		</>
	);
}
