import { Route, Routes } from "react-router-dom";
import { Login } from "./pages/Home/Login";
import { Register } from "./pages/Home/Register";
import { ResetPassword } from "./pages/Home/ResetPassword";
import { RegisterConfirmation } from "./pages/Home/RegisterConfirmation";
import { ConfirmEmail } from "./pages/Home/ConfirmEmail";
import { MyCourses } from "./pages/Courses/MyCourses";
import { Course } from "./pages/Courses/Course";
import { InstitutionCourses } from "./pages/Courses/InstitutionCourses";
import { Material } from "./pages/Material/Material";
import { HomePage } from "./pages/Home/Home";
import { Account } from "./pages/Account/Account";
import { RequireAuth } from "./components/Auth/RequireAuth";

const Router = () => {
	return (
		<Routes>
			<Route path="/">
				<Route index element={<HomePage />} />
				<Route path="login" element={<Login />} />
				<Route path="register" element={<Register />} />
				<Route path="edit" element={<ResetPassword />} />
				<Route path="confirm-email" element={<ConfirmEmail />} />
				<Route path="users">
					<Route path="confirmation" element={<RegisterConfirmation />} />
				</Route>
				<Route
					path="courses/:institutionNameSlug/course-codes"
					element={<InstitutionCourses />}
				/>
				<Route
					path="courses/my-courses"
					element={
						<RequireAuth>
							<MyCourses />
						</RequireAuth>
					}
				/>
				<Route path="courses/:courseId" element={<Course />} />
				<Route
					path="material/:materialId/:paginationNumber"
					element={<Material />}
				/>
				<Route
					path="material/:materialId"
					element={<Material />}
				/>
			</Route>
			<Route
				path="/account"
				element={
					<RequireAuth>
						<Account />
					</RequireAuth>
				}
			></Route>
		</Routes>
	);
};

export default Router;
