import { PostType } from "../Post/type/Post.types";

export type ForumType = PostType & {
	title: string;
	id: string;
	score: number;
};

export type ForumProps = {
	materialId: string;
	pageNumber: number;
	forum?: ForumType;
	queryParams?: any;
	forumsFilterName?: PostNamesType | "all";
	offset?: number;
};

export type CreateForumProps = ForumProps & {
	buttonLabel: string;
	setForum: any;
	postType: PostNamesType;
};

export type ForumItemProps = {
	forum: ForumType;
	queryParams?: any;
	onDelete: any;
	onUpdate: any;
	offset?: number;
};

export type StyledForumItemType = {
	percentageOfPageHeightFromBottom: number;
};

export const forumTypes = ["question" , "note" , "typo_correction"]
export type PostNamesType = typeof forumTypes[number];

export type ForumFormType = {
	name: PostNamesType;
	title: string;
	content: string;
};

export const postTypes: any = [
	{
		postType: "question",
		showPostType: "Show Questions",
		addPostType: "Ask a Question",
	},
	// {
		// postType: "typo_correction",
		// showPostType: "Show Typo Corrections",
		// addPostType: "Add a Typo Correction",
	// },
	// {
		// postType: "note",
		// showPostType: "Show Notes",
		// addPostType: "Add a Note",
	// },
];
