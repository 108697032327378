import "./App.css";
import "./styles/material.css";
import "./styles/forum.css";
import "./styles/header.css";
import "./styles/post.css";
import Router from "./Router";
import { BrowserRouter } from "react-router-dom";
import Layout from "./components/Layout";
import { UserContextProvider } from "./components/Auth/contexts/UserContext";

function App() {
	return (
		<div className={"App"}>
			<UserContextProvider>
				<BrowserRouter>
					<Layout>
						<Router />
					</Layout>
				</BrowserRouter>
			</UserContextProvider>
		</div>
	);
}

export default App;
