import { ReactElement, useState, useEffect } from "react";
import axios from "axios";

import { CommentsPostProps, CommentPostType } from "../type/Post.types";
import {CommentItem} from "./Item";

export function Comments({
	comment,
	parentPostId
}: CommentsPostProps): ReactElement | null {
	const [comments, setComments] = useState<CommentPostType[]>([]);

	useEffect(() => {
		axios
			.get(`/posts/${parentPostId}/responses`)
			.then((res) => {
				setComments(res.data);
			})
			.catch((err) => {
				console.log("err:", err.response);
			});
	}, [parentPostId]);

	useEffect(() => {
		const newComment: CommentPostType = comment!;
		if (typeof newComment === "undefined") {
			return;
		}
		setComments((currentComments) => [
			...currentComments,
			newComment,
		]);
	}, [comment]);

	const handleDelete = (deletedItemId: string) => {
		setComments(
			comments.filter((comment) => comment.id !== deletedItemId)
		);
	};

	const handleUpdate =
		(index: number) => (item: CommentPostType) => {
			let newList = [...comments];
			newList[index] = item;
			setComments(newList);
		};

	// return (
		// <>
			// {/* {!showUpdateForm && ( */}
				// {/* <div> */}
					// {/* <StyledComment> */}
						// {/* {comment.content} --- {comment.userName} : {timeAgo} */}
					// {/* </StyledComment> */}
					// {/* <button onClick={toggleForm}> */}
						// {/* Edit Comment */}
					// {/* </button> */}
					// {/* <button onClick={handleDelete}> */}
						// {/* Delete Comment */}
					// {/* </button> */}
				// {/* </div> */}
			// {/* )} */}
			// <Post
				// post={comment}
				// onDelete={onDelete}
				// onUpdate={onUpdate}
			// />
		// </>
	// );

	return (
		<>
			{comments.map(
				(commentPost: CommentPostType, index: number) => {
					return (
						<CommentItem
							comment={commentPost}
							key={commentPost.id}
							onDelete={handleDelete}
							onUpdate={handleUpdate(index)}
						/>
						)
				}
			)}
		</>
	);
}

