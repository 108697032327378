import { ReactElement, useState } from "react";
import Modal from "react-modal";
import axios from "axios";
import { useForm } from "react-hook-form";

import { ForumFormType, CreateForumProps } from "../Forum.types";

export function CreateForum({
	pageNumber,
	materialId,
	setForum,
	buttonLabel,
	postType,
}: CreateForumProps): ReactElement | null {
	const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
	const [submitting, setSubmitting] = useState<boolean>(false);

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<ForumFormType>({});

	const onSubmit = (data: ForumFormType) => {
		data.name = postType;
		axios
			.post(
				`/materials/${materialId}/pages/${pageNumber}/posts`,
				data
			)
			.then((res) => {
				const forum = res.data;
				setForum(forum);
				setModalIsOpen(false);
				reset();
			})
			.catch(() => {});

		setSubmitting(false);
	};

	return (
		<>
			<button onClick={() => setModalIsOpen(true)}>
				{buttonLabel}
			</button>

			<Modal
				isOpen={modalIsOpen}
				shouldCloseOnOverlayClick={false}
				onRequestClose={() => {
					setModalIsOpen(false);
				}}
				style={{
					overlay: { background: "" },
					content: { left: "50%", width: "45%" },
				}}
			>
				<div className="create-forum-modal">
						<button onClick={() => setModalIsOpen(false)}>
							Close
						</button>

					<div>
						<form
							className="post-form-container"
							onSubmit={handleSubmit((data) => onSubmit(data))}
						>
							<input
								placeholder="Title"
								className="post-form-textarea-title"
								{...register("title", {
									required: true,
								})}
							/>

							<textarea
								placeholder={buttonLabel}
								className="post-form-textarea-content"
								{...register("content", {
									required: true,
								})}
							/>

							{errors.title && <p>{errors.title.message}</p>}
							{errors.content && <p>{errors.content.message}</p>}

							<div>
								<input
									type="submit"
									disabled={submitting}
									value="Submit"
								/>
								<input
									type="button"
									onClick={() => setModalIsOpen(false)}
									value="Cancel"
								/>
							</div>
						</form>
					</div>
				</div>
			</Modal>
		</>
	);
}
