import { ReactElement, useState, useEffect } from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import Select from "react-select";
import axios from "axios";

type FormValues = {
	institution_id: string;
	email: string;
	name: string;
	password: string;
};

type InstitutionType = {
	name: string;
	name_slug: string;
	id: string;
};

type IInstitutionOption = {
	value?: string;
	label: string;
};

type GenericObject = { [key: string]: any };

export function Register(): ReactElement | null {
	const [serverErrors, setServerErrors] = useState<Array<string>>([]);
	const [registeredSuccessfully, setRegisteredSuccessfully] =
		useState<boolean>(false);
	const [submitting, setSubmitting] = useState<boolean>(false);
	const [institutionsExist, setInstitutionsExist] = useState<boolean>(false);
	const [institutionOptions, setInstitutionOptions] = useState<
		IInstitutionOption[]
	>([]);
	const {
		control,
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<FormValues>({});

	useEffect(() => {
		axios.get("institutions").then((res) => {
			structureInstitutions(res.data);
		});
	}, [institutionsExist]);

	const structureInstitutions = (institutions: [InstitutionType]) => {
		const instOpts = institutions.map((institution: InstitutionType) => ({
			value: institution.id,
			label: institution.name,
		}));
		setInstitutionOptions(instOpts);
	};

	const capitalize = (s: string) => s && s[0].toUpperCase() + s.slice(1);

	const stringifyErrorMessage = (errorKey: string, errorMessages: [string]) => {
		if (errorKey == "name") {
			errorKey = "username";
		}
		const errorMessage = `${capitalize(errorKey)}
			${errorMessages.join(", ").replace(/, ([^,]*)$/, " and $1")}`;
		return errorMessage;
	};

	const structureErrorMessages = (errors: GenericObject) => {
		let errorMessages = [];
		for (const errorKey in errors) {
			var errorMessage = stringifyErrorMessage(errorKey, errors[errorKey]);
			errorMessages.push(errorMessage);
		}
		return errorMessages;
	};

	const onSubmit: SubmitHandler<FormValues> = (data) => {
		const config = {
			headers: {
				"Content-Type": "application/json",
				Accept: "application/html",
			},
		};
		axios
			.post("/users/sign_up", data, config)
			.then(() => {
				setRegisteredSuccessfully(true);
			})
			.catch((err) => {
				const errorObject = err.response.data.message;
				const errorMessages = structureErrorMessages(errorObject);
				setServerErrors(errorMessages);
			});

		setSubmitting(false);
	};

	return (
		<>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div>
					<label>Institution</label>
					<Controller
						name="institution_id"
						control={control}
						rules={{ required: true }}
						render={({ field: { onChange } }) => (
							<Select
								options={institutionOptions}
								isSearchable={true}
								onChange={(selectedOption: IInstitutionOption | null) => {
									onChange(selectedOption?.value);
								}}
							/>
						)}
					/>
					{errors.institution_id && (
						<p className="form-error">Please select a University</p>
					)}
				</div>

				<div>
					<label>Email</label>
					<input
						{...register("email", {
							required: true,
							pattern: {
								value: /^\S*$/,
								message: "Invalid email. Please do not use spaces",
							},
						})}
					/>
					{errors.name && <p className="form-error">{errors.name.message}</p>}
				</div>

				<div>
					<label>Username</label>
					<input
						{...register("name", {
							required: true,
							pattern: {
								value: /^\S*$/,
								message: "Invalid username. Please do not use spaces",
							},
						})}
					/>
					{errors.name && <p className="form-error">{errors.name.message}</p>}
				</div>

				<div>
					<label>Password</label>
					<input
						type="password"
						{...register("password", {
							required: "You must specify a password",
							minLength: {
								value: 8,
								message: "min length is 8",
							},
						})}
					/>
					{errors.password && (
						<p className="form-error">{errors.password.message}</p>
					)}
				</div>

				{serverErrors && (
					<ul>
						{serverErrors.map((error) => (
							<li key={error} className="form-error">
								{error}
							</li>
						))}
					</ul>
				)}

				<div>
					<button type="submit" disabled={submitting}>
						Register
					</button>
				</div>
			</form>
			{registeredSuccessfully && (
				<div>
					<h3>You have successfully registered!</h3>
					<p>
						You will receive an email with instructions for how to confirm you
						email address in a few minutes.
					</p>
				</div>
			)}
		</>
	);
}
