import {
	useState,
	useEffect,
	createContext,
	Dispatch,
	SetStateAction,
	ReactNode,
	useContext,
} from "react";

type AuthUser = {
	name: string;
	institutionName: string;
};

type UserContextType = {
	user: AuthUser | null;
	login: (user: AuthUser) => void;
	logout: Dispatch<SetStateAction<null>>;
};

type UserContextProviderProps = {
	children: ReactNode;
};

function setLocalStorage(key: string, value: AuthUser | null) {
	// const now = new Date();
	//expiry needs to match cookie max age
	// const item = {value: value,
	// expiry: now.getTime() + 50000000000}
	//if user already exists delete it and set new one
	try {
		localStorage.setItem(key, JSON.stringify(value));
	} catch (e) {}
}

function getLocalStorage(
	key: string,
	initialValue: AuthUser | null
) {
	try {
		const value = localStorage.getItem(key);
		return value ? JSON.parse(value) : initialValue;
	} catch (e) {
		return initialValue;
	}
}

// export const UserContext = createContext<UserContextType | null>(null)
export const UserContext = createContext({} as UserContextType);

export const UserContextProvider = ({
	children,
}: UserContextProviderProps) => {
	// const [user, setUser] = useState<AuthUser | null>(null)
	const [user, setUser] = useState<AuthUser | null>(() =>
		getLocalStorage("user", null)
	);

	useEffect(() => {
		setLocalStorage("user", user);
	}, [user]);

	const login = (user: AuthUser)  => {
		setUser(user);
	};

	const logout = ()  => {
		setUser(null);
		localStorage.removeItem("user");
	};

	return (
		<UserContext.Provider value={{ user, login, logout }}>
			{children}
		</UserContext.Provider>
	);
};

export const useAuth = () => {
	return useContext(UserContext)
}
