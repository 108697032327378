import { ReactElement, useState } from "react";
import {
	ForumType,
	ForumProps,
	PostNamesType,
	postTypes,
} from "./Forum.types";
import { List } from "./List";
import { CreateForum } from "./Forms/Create";
// import { Search } from "../Material/Search";

export function Forum({
	pageNumber,
	materialId,
	offset
}: ForumProps): ReactElement | null {
	const [forum, setForum] = useState<ForumType>();
	const [showAllPosts, setShowAllPosts] =
		useState<boolean>(true);
	const [forumsFilterName, setForumsFilterName] = useState<
		PostNamesType | "all"
	>("all");

	const toggleShowPosts = () => {
		setShowAllPosts(!showAllPosts);
		var showPostType = "all";
		if (showAllPosts) {
			var showPostType = "mine";
		}
		setForumsFilterName(showPostType);
	};

	return (
		<>
			<div className="forum-options-container">
				<div className="forum-options-item">
					{postTypes.map((postType: any) => {
						return (
							<div key={postType.postType}>
								<CreateForum
									materialId={materialId}
									pageNumber={pageNumber}
									setForum={setForum}
									buttonLabel={postType.addPostType}
									postType={postType.postType}
								/>
							</div>
						);
					})}

					<div>
						<button onClick={toggleShowPosts}>
							{showAllPosts
								? "Show My Questions"
								: "Show All Questions"}
						</button>
					</div>
				</div>

				{/* <Search */}
					{/* materialId={materialId} */}
					{/* pageNumber={pageNumber} */}
					{/* searchPrompt={"Search for questions and answers only for this page"} */}
				{/* /> */}
			</div>

			<List
				pageNumber={pageNumber}
				materialId={materialId}
				forum={forum}
				forumsFilterName={forumsFilterName}
				offset={offset}
			/>
		</>
	);
}
