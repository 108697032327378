import { ReactElement, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { CourseSectionProps } from "../type/Course.types";

export function Course({
	course,
}: CourseSectionProps): ReactElement | null {
	const navigate = useNavigate();
	console.log("course:", course);

	const handleClick = (courseSectionId: string) => {
		const data = {
			courseId: courseSectionId,
		};
		axios.post("/users/courses/", data).then(() => {
			navigate("/courses/my-courses");
		});
	};

	return (
		<div className="sections">
			<h4>{course.title}</h4>
			<button onClick={() => handleClick(course.id)}>
				Add to my folders
			</button>

			{/* {course.sections.map((courseSection: CourseType) => { */}
			{/* return ( */}
			{/* <div key={courseSection.id}> */}
			{/* <div className="info"> */}
			{/* <div>section: {courseSection.sectionCode}</div> */}
			{/* <div>semester: {courseSection.semesterDate}</div> */}
			{/* <div>instructor: {courseSection.instructorName}</div> */}
			{/* </div> */}
			{/* <button */}
			{/* onClick={() => handleClick(courseSection.id)} */}
			{/* > */}
			{/* Add to my courses */}
			{/* </button> */}
			{/* <p>{serverErrors}</p> */}
			{/* </div> */}
			{/* ); */}
			{/* })} */}
		</div>
	);
}
