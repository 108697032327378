import { ReactElement, useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ResponsePostType } from "./type/Post";
import { CreateForumQueryParam } from "../../utils/CreateForumQueryParam";
import { TimeAgo } from "../../utils/TimeAgo";

export function Responses(): ReactElement | null {
	const [items, setItems] = useState([]);
	const navigate = useNavigate();

	useEffect(() => {
		axios
			.get("/users/posts/responses")
			.then((res) => {
				setItems(res.data);
			})
	}, []);

	const handleClick = (forum: ResponsePostType) => {
		const navLink = CreateForumQueryParam(forum);
		console.log("navLink:", navLink);
		navigate(navLink);
	};

	return (
		<>
			{items.map((response: ResponsePostType) => {
				return (
					<div
						key={response.id}
						className="basic-card-container account-post-container"
						onClick={() => handleClick(response)}
					>
						<div className="post-title-container">
							<div className="course">
								{response.courseCode} &#183; {response.courseTitle} &#183; {TimeAgo(response.createdAt)}
							</div>

							<div className="title">{response.forumTitle}</div>
						</div>

						<div className="content">{response.content}</div>
					</div>
				);
			})}
		</>
	);
}
