import { ReactElement } from "react";
import { Link } from "react-router-dom";
import { Action } from "../Auth/Action";
import { Account } from "../Account/Account";

export function Header(): ReactElement | null {
	return (
		<header>
			<div className="martlet-container">
				<Link to="/" className="martlet">
					<h3>Martlet</h3>
				</Link>
			</div>

			<div className="options-container">
				<Link to="/courses/my-courses" className="option">
					My Folders
				</Link>
				<Link to="/account" className="option">
					<Account />
				</Link>
				<Action />
			</div>
		</header>
	);
}
